//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HoursOfOperation from '@/components/footer/HoursOfOperation'
import SocialMediaLinks from '@/components/footer/SocialMediaLinks'
import MenuCategories from '@/components/footer/MenuCategories'

export default {
  name: 'NewAppFooter',
  components: {
    SocialMediaLinks,
    HoursOfOperation,
    MenuCategories
  },
  data() {
    return {}
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
    siteMap() {
      return [
        {
          // According to https://github.com/kazupon/vue-i18n/issues/108#issuecomment-292812707
          title: this.$i18n.t('welcome'),
          siteURL: '/'
        },
        {
          title: this.$i18n.t('menu'),
          siteURL: '/menu'
        },
        {
          title: this.$i18n.t('our_story'),
          siteURL: '/ourstory'
        },
        {
          title: this.$i18n.t('order_online'),
          siteURL: '/order'
        },
        {
          title: this.$i18n.t('reservation'),
          siteURL: '/reservations'
        }
      ]
    }
  }
}
