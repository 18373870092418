//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import VueGallery from 'vue-gallery'
export default {
  name: 'AboutGallery',
  components: {
    // 'gallery': VueGallery
  },
  data() {
    return {
      value3: 0,
      setting: {
        autoplay: false,
        autoplaySpeed: 2000,
        dots: 'inside',
        trigger: 'click',
        arrow: 'hover'
      },
      images: ['/img/001.jpg', '/img/006.jpg', '/img/005.jpg', '/img/004.jpg'],
      index: null
    }
  }
}
