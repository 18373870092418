//
//
//
//
//
//
//
//
//
//
//
//

import InfoPanel from '@/components/content/InfoPanel'
export default {
  name: 'InfoPanelSection',
  components: { InfoPanel },
  data() {
    return {
      infoPanels: [
        {
          title: 'Fresh Ingredients',
          description:
            'We source high quality ingredients and offer a wide selection of seasonal flavors.',
          pictureURL: 'heroes/dining.jpg',
          callToAction: {
            name: 'View Menu',
            actionLink: '/menu'
          }
        },
        {
          title: 'Modern Classics',
          description:
            'Our dishes are modern classic flavors spanning different provinces of China. You can expect northern Peking Duck to Hong Kong banquet dishes.',
          pictureURL: 'heroes/food.jpg',
          callToAction: {
            name: 'Order Now',
            actionLink: '/order'
          }
        },
        {
          title: 'Our Team',
          description:
            ' We appreciate the patronage and hope our food and service gives you a reason to visit us again. ',
          pictureURL: 'heroes/team.jpg',
          callToAction: {
            name: 'Learn more',
            actionLink: '/ourstory'
          }
        }
      ]
    }
  }
}
