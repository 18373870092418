//
//
//
//
//
//
//
//
//
//

// require(['mojo/signup-forms/Loader'], function (L) {
//   L.start({
//     baseUrl: 'mc.us17.list-manage.com',
//     uuid: '9cf5466d870ad11825d19cb66',
//     lid: 'adc21d73a7'
//   })
// })

export default {
  name: 'MailChimp'
}
