//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    cellTitle: {
      type: String,
      required: true
    },
    cellSubtitle: {
      type: String,
      required: true
    },
    cellLinkTitle: {
      type: String,
      defaul: null
    },
    cellLink: {
      type: String,
      default: null
    }
  },
  name: 'InformationCell',
  data() {
    return {}
  }
}
