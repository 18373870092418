//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import VueGallery from 'vue-gallery'

export default {
  name: 'MiGallery',
  components: {
    gallery: () => import('vue-gallery')
  },
  props: {
    galleryData: Array
  },
  data() {
    return {
      index: null,
      setting: {
        autoplay: false,
        autoplaySpeed: 2000,
        dots: 'inside',
        trigger: 'click',
        arrow: 'hover'
      }
    }
  },
  computed: {
    imageURLs() {
      const imagePaths = []
      for (const index in this.galleryData) {
        // Get Assets URL
        const item = this.galleryData[index]
        const url = require(`@/assets${item.href}`)
        imagePaths.push(url)
      }

      return imagePaths
    }
  }
}
