//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PictureGallery',
  components: {},
  data() {
    return {
      galleryList: [
        {
          title_EN: 'Shrimp-stuffed Chicken',
          title_ZH: '百花雞',
          imgURL: 'foodGallery/001.jpg'
        },
        {
          title_EN: 'Peking Duck Roast',
          title_ZH: '北京烤鴨（三吃）',
          imgURL: 'foodGallery/002.jpg'
        },
        {
          title_EN: 'Steamed Lobster with Vermicelli',
          title_ZH: '薑蔥蒸龍蝦',
          imgURL: 'foodGallery/003.jpg'
        },
        {
          title_EN: 'Empress Shrimp',
          title_ZH: '溜子蝦仁',
          imgURL: 'foodGallery/004.jpg'
        },
        {
          title_EN: 'Soy-Braised Duck',
          title_ZH: '潮州滷水鴨',
          imgURL: 'foodGallery/005.jpg'
        },
        {
          title_EN: 'Braised Pork Shank',
          title_ZH: '紅燒元蹄',
          imgURL: 'foodGallery/006.jpg'
        },
        {
          title_EN: 'Spicy Shanghai Noodles',
          title_ZH: '上海炸醬麵',
          imgURL: 'foodGallery/007.jpg'
        }
      ],
      galleryOptions: {
        infinite: true,
        navButtons: false,
        dots: true,
        autoplay: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              navButtons: true
            }
          }
        ]
      }
    }
  }
}
