//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ItemSectionPanel',
  components: {},
  props: {
    item: Object,
    itemCategory: String
  },
  data() {
    return {
      // item: this.dish
    }
  },
  computed: {},
  methods: {
    itemIsADrinkOrSetMeal() {
      if (
        [
          'exoticdrink',
          'cocktail',
          'housewine',
          'orientalwine',
          'bubbletea',
          'premiumliquor',
          'setmeal'
        ].includes(this.itemCategory)
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
