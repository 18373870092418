//
//
//
//
//
//
//
//
//
//
//
//

import {
  faFacebookSquare,
  faFoursquare,
  faInstagram,
  faTripadvisor,
  faTwitterSquare,
  faYelp
} from '@fortawesome/free-brands-svg-icons'
export default {
  name: 'SocialMediaLinks',
  data() {
    return {
      socialMediaMap: [
        {
          brandIcon: 'faFacebookSquare',
          mediaURL: 'https://www.facebook.com/tastycuisine/',
          icon: faFacebookSquare
        },
        {
          brandIcon: 'faInstagram',
          mediaURL: 'https://www.instagram.com/tasty.cuisine/',
          icon: faInstagram
        },
        {
          brandIcon: 'faTwitter',
          mediaURL: 'https://twitter.com/tastycuisine',
          icon: faTwitterSquare
        },
        {
          brandIcon: 'faYelp',
          mediaURL:
            'https://www.yelp.com/biz/tasty-cuisine-chinese-restaurant-des-plaines',
          icon: faYelp
        },
        {
          brandIcon: 'faFoursquare',
          mediaURL:
            'https://foursquare.com/v/tasty-cuisine/4b91bd26f964a520d8d333e3',
          icon: faFoursquare
        },
        {
          brandIcon: 'faTripadvisor',
          mediaURL:
            'https://www.tripadvisor.com/Restaurant_Review-g35889-d464386-Reviews-Tasty_Cuisine_Chinese_Restaurant-Des_Plaines_Illinois.html',
          icon: faTripadvisor
        }
      ]
    }
  },
  computed: {},
  methods: {
    getIcon(iconName) {
      return fab[iconName]
    }
  }
}
