//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'OnlineOrderButton',
  props: {
    buttonTitle: String,
    buttonImg: String,
    buttonHref: String
  },
  data() {
    return {}
  },
  computed: {
    buttonImagePath() {
      return require(`@/assets/img/onlineOrdering/${this.buttonImg}`)
    }
  },
  methods: {
    onClick(buttonHref) {
      console.log('clicked...')
      // debugger
      this.$gtm.push({ event: 'orderClick', orderFrom: buttonHref })
    }
  }
}
