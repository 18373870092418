//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      value3: 0,
      setting: {
        autoplay: false,
        autoplaySpeed: 2000,
        dots: 'inside',
        trigger: 'click',
        arrow: 'hover'
      },
      images: [
        '/img/001.jpg',
        '/img/002.jpg',
        '/img/003.jpg',
        '/img/004.jpg',
        '/img/005.jpg',
        '/img/006.jpg',
        '/img/007.jpg'
        // '/img/008.jpg'
      ],
      index: null
    }
  }
}
