//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    itemTitle: String,
    itemDescription: String
  },
  name: 'TimelineItem',
  data() {
    return {}
  }
}
