//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MiLIst',
  data() {
    return {
      listTitle: String,
      listDataMap: Object,
      cellDisableLinks: Boolean
    }
  }
}
