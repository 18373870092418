//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      columns1: [
        {
          title: 'Days',
          key: 'days'
        },
        {
          title: 'Time',
          key: 'time'
        }
      ],
      columns2: [
        {
          title: '星期',
          key: 'days'
        },
        {
          title: '時間',
          key: 'time'
        }
      ],
      data1: [
        {
          days: 'Monday thru Thursday',
          time: '11am-8:30pm'
        },
        {
          days: 'Friday & Saturday',
          time: '11am-8:30pm'
        },
        {
          days: 'Sunday',
          time: '11am-8:30pm'
        }
      ],
      data2: [
        {
          days: '星期一 到 星期四',
          time: '11:00 早上 到 8:30 晚上'
        },
        {
          days: '星期五 & 星期六',
          time: '11:00 早上 到 8:30 晚上'
        },
        {
          days: '星期天',
          time: '1:00 下午 到 8:30 晚上'
        }
      ]
    }
  }
}
