//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import SITEMAP from '@/assets/json/itemSectionKeyValueMapCOPY'

// const json = require('@/assets/json/itemSectionKeyValueMap.json')

export default {
  name: 'MenuCategories',
  data() {
    return {}
  },
  computed: {
    // siteMapMenuCategories: json
    siteMapMenuCategories() {
      return [
        {
          title: this.$t('Exotic Drink'),
          key: 'exoticdrink',
          description: null
        },
        {
          title: this.$t('Cocktails'),
          key: 'cocktail',
          description: null
        },
        {
          title: this.$t('House Wine'),
          key: 'housewine',
          description: null
        },
        {
          title: this.$t('Oriental Wine'),
          key: 'orientalwine',
          description: null
        },
        {
          title: this.$t('Premium Liquor'),
          key: 'premiumliquor',
          description: ['Double Shots']
        },
        {
          title: this.$t('Boba Tea'),
          key: 'bubbletea',
          description: null
        },
        {
          title: this.$t('Appetizer - 前菜'),
          key: 'appetizer',
          description: null
        },
        {
          title: this.$t('Soup - 湯羹'),
          key: 'soup',
          description: null
        },
        {
          title: this.$t('BBQ - 燒臘'),
          key: 'bbq',
          description: null
        },
        {
          title: this.$t('Signature - 特色名菜'),
          key: 'signature',
          description: null
        },
        {
          title: this.$t('Poultry - 雞肉'),
          key: 'poultry',
          description: null
        },
        {
          title: this.$t('Beef - 牛肉'),
          key: 'beef',
          description: null
        },
        {
          title: this.$t('Pork - 豬肉'),
          key: 'pork',
          description: null
        },
        {
          title: this.$t('Vegetables - 素菜'),
          key: 'vegetables',
          description: null
        },
        {
          title: this.$t('Seafood - 海鮮'),
          key: 'seafood',
          description: null
        },
        {
          title: this.$t('Tofu - 豆腐'),
          key: 'tofu',
          description: null
        },
        {
          title: this.$t('Noodle Soup - 湯麵'),
          key: 'noodlesoup',
          description: null
        },
        {
          title: this.$t('LoMein - 撈麵'),
          key: 'lomein',
          description: null
        },
        {
          title: this.$t('Fried Rice - 炒飯'),
          key: 'friedrice',
          description: null
        },
        {
          title: this.$t('ChowFun ChowMein - 炒粉炒麵'),
          key: 'chaofenmian'
        },
        {
          title: this.$t("Today's Special"),
          key: 'setmeal',
          description: [
            'Lunch Special Hours: 11am-2pm',
            'Served with one crabmeat raggon and one egg rolll, chicken fried rice or steamed rice',
            'LoMein does not come with rice'
          ]
        }
      ]
    }
  }
}
