//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageHeaderHero',
  props: {
    heroTitle: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    },
    heroImagePath: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {
    // heroImageURL() {
    //   if (this.heroImagePath == null) {
    //     return ''
    //   } else {
    //     const imageURL = require(`@/assets/img/${this.heroImagePath}`)
    //     return {
    //       'background-image': `url(${imageURL})`
    //     }
    //   }
    // }
  }
}
