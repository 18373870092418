//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseButton',
  props: {
    buttonData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {}
}
