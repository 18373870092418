//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InfoPanel',
  props: {
    infoPanelData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {}
}
