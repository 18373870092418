//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MiModernAlert',
  props: {
    alertData: Object
  },
  data() {
    return {}
  }
}
