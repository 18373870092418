//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TimelineItem from '@/components/timeline/TimelineItem'
export default {
  name: 'TimelineSection',
  components: {
    TimelineItem
  },
  data() {
    return {
      timelineMap: [
        {
          title: 'Today',
          description:
            'Continues serving locals the best and most authentic cookings in different regions of Canton. 今天，我們還是堅持傳統式粵菜給我們的來客。'
        },
        {
          title: '2016',
          description: 'Two Decades of service!'
        },
        {
          title: '2006',
          description: '10 Years Anniversary! 十週年！'
        },
        {
          title: '2004',
          description:
            'Participated in 4th CSTV Chinese Cuisine Show with Martin Yan. 參加2004年芝加哥粵菜比賽'
        },
        {
          title: '1996',
          description:
            'Serving our first orders of authentic Canton flavors to the Northwest suburbs of Chicago 第一年營業'
        }
      ]
    }
  }
}
