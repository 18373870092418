//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from '@/components/button/BaseButton'

const json = require('@/assets/json/itemSectionKeyValueMap.json')

export default {
  name: 'ItemSectionSelector',
  components: {
    BaseButton
  },
  data() {
    return {
      buttonMap: json
    }
  }
}
