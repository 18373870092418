//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ItemSectionPanel from '@/components/menu/ItemSectionPanel'
export default {
  name: 'ItemSection',
  components: {
    ItemSectionPanel
  },
  props: {
    sectionJSON: {
      type: Array,
      required: true
    },
    sectionTitle: {
      type: String,
      required: true
    },
    sectionAnchor: {
      type: String,
      required: true
    },
    sectionDescription: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      items: this.sectionJSON
    }
  }
}
