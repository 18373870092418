//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TastyTimeline',
  data() {
    return {}
  },
  computed: {
    thisYear() {
      var dateObj = new Date()
      var year = dateObj.getFullYear()
      return year
    }
  }
}
