//
//
//
//
//
//

// import MiGallery from '@/components/gallery/MiGallery'

export default {
  name: 'WaterfallGallery',
  components: {
    // MiGallery
  },
  props: {
    listOfItems: Object
  },
  data() {
    return {
      items: [
        {
          i: 1
        }
      ],
      imageGalleryPics: [
        {
          title: 'Private Dining Room',
          href: '/img/privateDining/001.jpg',
          size: { h: 300, w: 400 }
          // description: 'hello world'
        },
        {
          title: 'Private Dining Room',
          href: '/img/privateDining/002.jpg',
          size: { h: 200, w: 400 }
        },
        {
          title: 'Private Dining Room',
          href: '/img/privateDining/003.jpg',
          size: { h: 500, w: 400 }
        },
        {
          title: 'Main Dining Area',
          href: '/img/privateDining/004.jpg',
          size: { h: 200, w: 400 }
        },
        {
          title: 'Koi Tank',
          href: '/img/privateDining/005.jpg',
          size: { h: 500, w: 400 }
        }
      ]
    }
  }
}
